import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { gridMaxWidth, gridPadding } from 'styles/theme';
import { tabletUp } from 'styles/utils';

const containerCss = ({ fluid, size }) => {
  const containerMaxWidth = {
    sm: 610,
    md: 770,
    lg: gridMaxWidth,
  };

  return css`
    margin: 0 auto;
    padding-right: ${gridPadding}px;
    padding-left: ${gridPadding}px;
    width: 100%;

    ${tabletUp} {
      /* When container is fluid, it won't have its width limited */
      max-width: ${fluid
        ? 'initial'
        : // Make sure the container always has padding without it taking space
          `${containerMaxWidth[size] + gridPadding * 2}px`};
    }
  `;
};

/**
 * A basic layout element that delimits content (children) inside of it.
 *
 * @param {object} props
 */
function Container({ children, className = '', fluid = false, size = 'lg' }) {
  return (
    <div css={containerCss({ fluid, size })} className={className}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * Keeps the container 100% in width.
   * Otherwise has `max-width` on desktop
   */
  fluid: PropTypes.bool,
  /** How wide the container's max-width should be */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default Container;
