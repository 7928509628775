import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { colors } from 'styles/theme';
import { tabletUp } from 'styles/utils';

import Container from '../Container/Container';

const cardCss = (backgroundColor, forceRounded) =>
  css`
    ${backgroundColor === 'purple' && `color: ${colors.white};`}
    position: relative;
    padding: 45px 18px;

    ${tabletUp} {
      padding-right: 77px;
      padding-left: 77px;
    }

    &::before {
      background-color: ${colors[backgroundColor]};
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: -1;
      transform: skewY(-2deg);

      ${forceRounded && `border-radius: 70px;`}

      ${tabletUp} {
        border-radius: 70px;
      }
    }
  `;

const containerCss = forceRounded => {
  if (forceRounded) {
    return;
  }

  /* When on smaller screen, by default, card is flat and from edge to edge. */
  return css`
    padding: 0;

    ${tabletUp} {
      padding: 0 18px;
    }
  `;
};

/**
 * @param {object} props
 */
function Card({ children, className, color = 'pale', forceRounded = false }) {
  return (
    <Container css={containerCss(forceRounded)}>
      <div css={cardCss(color, forceRounded)} className={className}>
        {children}
      </div>
    </Container>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Card's color scheme */
  color: PropTypes.oneOf([
    'offWhite',
    'pale',
    'purple',
    'sunYellow',
    'brightYellow',
  ]),
  /**
   * If forced, the card will always be rounded.
   * Otherwise, it would be flat on smaller screens
   */
  forceRounded: PropTypes.bool,
};

export default Card;
