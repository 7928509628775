import { css } from '@emotion/react';

import { breakpoints, zLayers } from 'styles/theme';
import { mobileOnly } from 'styles/utils';

import Shape from 'components/shared/Shape/Shape';
import Card from 'components/shared/Card/Card';
import Heading from 'components/shared/Heading/Heading';
import Text from 'components/shared/Text/Text';
import Container from 'components/shared/Container/Container';

const goodByeCss = css`
  margin-top: 110px;
  ${mobileOnly} {
    margin: 0;
  }
`;

const imageCss = css`
  max-height: 250px;
  z-index: ${zLayers.behindAll};
  margin: 0 auto;
  margin-bottom: -60px;
  width: 100%;
  margin-top: 25px;
`;

const contentCss = css`
  position: relative;
  margin: 0 auto;
  @media (min-width: ${breakpoints.tabletPx}) {
    max-width: 685px;
  }
`;

const leftShapeCss = css`
  bottom: 60px;
  left: -80px;
  transform: rotate(-3deg);
  z-index: ${zLayers.behindAll};
`;

const rightShapeCss = css`
  top: 50px;
  right: -40px;
  transform: rotate(-3deg);
  z-index: ${zLayers.behindAll};
`;

const cardCss = css`
  text-align: center;
  z-index: 1;
`;

const titleCss = css`
  margin-bottom: 40px;
`;

const paragraphCss = css`
  margin-top: 20px;
  font-size: 15px;
`;
const pageCss = css`
  position: relative;
  height: 100vh;
`;

function GoodByePage() {
  return (
    <div css={pageCss}>
      <>
        <Container css={goodByeCss}>
          <img src="/media/goodbye/goodbye.svg" alt="goodbye" css={imageCss} />
          <div css={contentCss}>
            <Shape
              variant="pill"
              size="md"
              color="purple"
              zIndex={zLayers.behindAll}
              css={leftShapeCss}
              tablet
              desktop
            />
            <Shape
              variant="pill"
              size="md"
              color="offWhite"
              zIndex={zLayers.behindAll}
              css={rightShapeCss}
              tablet
              desktop
            />

            <Card color="pale" css={cardCss}>
              <Heading variant="h2" css={titleCss}>
                Time to say goodbye
              </Heading>
              <Text isMui={false} css={paragraphCss}>
                The Velieve service is no longer available in Ireland.
                <p>
                  If you have an unused kit, please contact our customer support
                  at{' '}
                  <a href="mailto:support@velieve.io">
                    <p>support@velieve.io</p>
                  </a>
                </p>
              </Text>
            </Card>
          </div>
        </Container>
      </>
    </div>
  );
}

export default GoodByePage;
