import PropTypes from 'prop-types';

import { fontSize } from 'styles/theme';

import TextWithoutMui from '../Text/TextWithoutMui';

const propsByVariant = {
  h1: {
    fontSizes: {
      mobileOnly: fontSize['4xl'],
      tabletUp: fontSize['5xl'],
    },
  },
  h2: {
    fontSizes: {
      mobileOnly: fontSize['3xl'],
      tabletUp: fontSize['5xl'],
    },
  },
  h3: {
    fontSize: fontSize['4xl'],
  },
  h4: {
    fontSize: fontSize.xl,
  },
  h5: {
    fontSize: fontSize.lg,
  },
  h6: {
    fontSize: fontSize.md,
  },
};

function Heading({ children, variant, ...restProps }) {
  return (
    <TextWithoutMui
      as={variant}
      fontWeight={500}
      {...propsByVariant[variant]}
      {...restProps}
    >
      {children}
    </TextWithoutMui>
  );
}

Heading.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default Heading;
